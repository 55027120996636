<template>
  <div class="table" @scroll.passive="getScroll($event)">
    <span class="back-tit">班级详情</span>
    <div class="row-bg">
      <span v-text="classInfo.className" style="margin-right: 25px"></span>
    </div>
<!--     classInfo：{{classInfo}}-->
    <el-tabs class="tb-tabs" v-model="activeName">
      <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
        <!-- <keep-alive> -->
        <class-detail
          v-if="activeName==item.key"
          :type="item.key"
          :pageFrom="pageFrom"
          :classInfo="classInfo"
          :classStudent="classStudent"
          :scrollTopTable="scrollTopTable"
          @getClassTeacherInfoById="getClassTeacherInfoById"
        ></class-detail>
        <!-- </keep-alive> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getClassTeacherInfoById, getClassStudentInfoById } from '@/api/merchantClass'
import ClassDetail from '@/components/merchantClass/detail-pane'

export default {
  watch: {
    '$route': function (to, from) {
      this.reload() // 监测到路由发生变化时调用
    },

    activeName (val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          courseId: this.course_id,
          classID: this.class_id,
          productId: this.product_id,
          tab: val
        }
      })
    },
    classInfo (newVal) {
      // console.log('classInfo', newVal)
      this.$router.push({
        path: this.$route.path,
        query: {
          courseId: newVal.courseId,
          classID: this.class_id,
          productId: this.product_id,
          tab: this.activeName
        }
      })
    }
  },
  components: { ClassDetail },
  inject: ['reload'],
  data () {
    return {
      pageFrom:'', //教师端还是商户端
      class_id: '', // 班级id
      course_id: '', // 课程id
      product_id: '', // 收费课程id
      classInfo: {}, // 班级基本信息
      classStudent: [], // 班级学员列表
      activeName: 'Info',
      tabMapOptions: [
        { label: '基本信息', key: 'Info' },
        { label: '班级学员', key: 'Student' },
        { label: '班级排课', key: 'Lesson' },
      ],
      scrollTopTable: 0
    }
  },
  created () {
    if (Boolean(this.$route.query.tab)) {
      this.activeName = this.$route.query.tab
    }
    this.pageFrom = this.$route.query.pageFrom
    this.class_id = this.$route.query.classID
    this.course_id = this.$route.query.courseId
    this.product_id = this.$route.query.productId
    this.getClassTeacherInfoById()
  },

  beforeDestroy () {
    sessionStorage.setItem('isAsync', false)
  },
  methods: {
    back () {
      this.$router.go(-1)
    },

    getScroll (event) {
      // console.log(event.target.scrollTop)
      this.scrollTopTable = event.target.scrollTop
    },


    // 获取班级信息
    async getClassTeacherInfoById () {
      const res = await getClassTeacherInfoById({
        class_id: this.class_id
      })
      this.classInfo = res.body
    },


    // 获取班级学员
    async getClassStudentInfoById () {
      const res = await getClassStudentInfoById({
        class_id: this.$route.query.classID
      })
      this.classStudent = res.body
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
.back-tit {
  margin-left: 0px !important;
}
.row-bg {
  padding: 15px !important;
  margin-top: 15px !important;
}
</style>
