<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>修改排课时间</span>
    </div>
    <span style="color: #efd760">
      <i class="el-icon-warning"></i>
      修改或删除一次排课，其他未上课排课将按修改后的上课时间先后顺序依次顺延，且不打乱上课课次顺序
    </span>
    <el-row style="margin-top: 16px;">
      <el-button size="small" type="primary" @click="add">增加上课时间</el-button>
    </el-row>
    <el-row style="margin-top: 16px;height: 450px;overflow-y: scroll">
      <el-col :span="8">
        <li v-for="item in dataList" class="liClass">
          {{item.courseUnitlist[0].sort}}.
          {{item.courseUnitlist[0].title}}
        </li>
      </el-col>
      <el-col :span="10">
        <li v-for="(item , index) in dataListTime" class="liClass">
          <span>
            {{item.start_time}}
            <i class="el-icon-edit margin-l-5" @click="edit(item,index)"></i>
            <i class="el-icon-remove-outline margin-l-5" @click="remove(index)"></i>
          </span>
        </li>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" >确 定</el-button>
      <el-button type="info"  @click="close">取 消</el-button>
    </el-row>
    <add-lessontime :show="addLessonDialog" @close="addLessonDialog = false" :editCurrentIndex="editCurrentIndex" :lessonTime="lessonTime" @newLessonTime="LessontimeCallback"></add-lessontime>
  </el-dialog>
</template>

<script>

import { getLessonInfoByClassId,editBatTime } from '@/api/merchantClass'
import addLessontime from '../addLessontime/index'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    isDesc: {
      default: false,
      type: Boolean
    },
  },
  components: {
    addLessontime
  },
  data () {
    return {
      dataList:[],
      dataListTime:[],
      addLessonDialog:false,
      lessonTime:'',
      editCurrentIndex:-1
    }
  },
  methods: {
    LessontimeCallback(data) {
      if(this.editCurrentIndex === -1) {
        this.dataListTime.push({
          start_time:data
        })
      }else {
        this.dataListTime[this.editCurrentIndex].start_time = data
      }

      this.dataListTime.forEach(item => {
        item.sortTime = new Date(item.start_time).getTime()
      })
      this.dataListTime.sort(this.compare('sortTime'))
    },
     compare(property){
      return (a,b)=>{
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      }
    },
    remove(index) {
      this.dataListTime.splice(index,1)
    },
    edit(row,index) {
      this.addLessonDialog = true
      this.lessonTime = row.start_time
      this.editCurrentIndex = index
    },
    add() {
      if(this.dataListTime.length === this.dataList.length) {
        window.$msg('目前所有排课都有上课时间，如需修改请编辑具体某一排课时间',2)
        return false
      }
      this.addLessonDialog = true
      this.lessonTime = ''
      this.editCurrentIndex = -1
    },
    async getLessonInfoByClassId () { // 获取班级排课列表
      const res = await getLessonInfoByClassId({
        pageNum: 1,
        pageSize: 10000,
        class_id: this.$route.query.classID,
        status:'未上课',
        isDesc: this.isDesc
      })
      this.dataList = []
      this.dataListTime = []
      res.body.list.forEach((item)=>{
        this.dataList.push(item)
        this.dataListTime.push(item)
      })
    },
    openDialog () {
      this.getLessonInfoByClassId()
    },

    close () {
      this.$emit('close')
    },

    confirm () {
      if(this.dataList.length === this.dataListTime.length) {
        let thembBatTime = []
        this.dataListTime.forEach(item => {
          thembBatTime.push(item.start_time)
        })
        editBatTime({
          classId:this.$route.query.classID,
          startTimes:thembBatTime
        }).then((res)=> {
          if(res.state === 'success') {
            window.$msg('排课时间修改成功')
            this.close()
            this.$emit('updateView')
          }
        })
      }else {
        window.$msg('请保证每个排课都有排课时间再保存',2)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.el-dialog__body {
  padding-top: 16px !important;
}
.liClass {
  line-height: 30px;
  height: 45px;
}
.margin-l-5 {
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #F7CF47;
}
</style>
