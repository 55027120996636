<template>
  <el-dialog
    title="批量开放补课视频"
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
    width="900px"
    append-to-body
  >
    <el-row>
      <el-col :span="13">
        <el-card shadow="nover">
          <span class="tips"
          >选择要操作的补课视频：({{ taskCheck.length }})</span
          >
          <el-row style="margin-bottom: 10px; margin-top: 15px">
            <el-checkbox v-model="checked" @change="checkedAll" />
            全选/反选
          </el-row>
          <el-tree
            ref="tree"
            :data="datalist"
            show-checkbox
            v-if="randomKey"
            node-key="id"
            @check-change="handleCheckChange"
            :props="defaultProps"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span
                v-if="data.type === '授课模式' || data.type === '任务模式'"
                class="taskpracticePurpose"
              >课节</span
              >
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="10" :offset="1">
        <el-card shadow="nover">
          <span class="tips"
          >选择要操作的学员：({{ studentCheck.length }})</span
          >
          <el-row style="margin-bottom: 10px; margin-top: 15px">
            <el-checkbox v-model="checkedStudent" @change="checkedAllStudent" />
            全选/反选
          </el-row>
          <el-tree
            ref="treeStudent"
            :data="studentlist"
            show-checkbox
            node-key="user_id"
            @check-change="handleCheckStudentChange"
            :props="defaultStudentProps"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }} [{{ data.loginName }}]</span>
            </span>
          </el-tree>
        </el-card>
        <el-date-picker
          style="margin-top: 15px; width: 360px"
          v-model="chooseDate"
          type="datetimerange"
          start-placeholder="有效期开始日期"
          end-placeholder="有效期结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
      </el-col>
    </el-row>

    <div
      style="
        display: flex;
        margin-top: 20px;
        width: 100%;
        justify-content: flex-end;
      "
    >
      <el-button size="small" @click="batSetStatus('已通过')"
      >批量开放</el-button
      >
      <el-button size="small" @click="batSetStatus('未通过')"
      >批量关闭</el-button
      >
      <el-button type="primary" size="small" @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getClassStudentInfoById,getTaskByCourse,insertAllWithStatus } from '@/api/merchantClass'
import { getListAttachment } from "@/api/research/curriculum";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    courseId: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      randomKey: false,
      datalist: [],
      studentlist: [],

      studentCheck: [],
      taskCheck: [],
      chooseDate: [],

      defaultProps: {
        children: "children",
        label: "title",
      },
      defaultStudentProps: {
        children: "children",
        label: "userName",
      },

      checked: false, // checkbox的值
      checkedStudent: false,
    };
  },
  methods: {
    openDialog() {
      this.taskCheck = [];
      this.chooseDate = [];
      this.randomKey = false;
      this.studentCheck = [];
      this.getTaskByUserAndCourse();
      this.getClassStudentInfoById()
    },

    async getClassStudentInfoById () {
      const res = await getClassStudentInfoById({
        class_id: this.$route.query.classID
      })
      if (res.state === 'success') {
        this.studentlist = res.body
      }
    },

    async getCourseWareList(courseUnitId, index) {
      const res = await getListAttachment({
        courseUnitId: courseUnitId,
        pageNum: 1,
        pageSize: 10000,
        type: "补课视频",
      });
      if (res.body) {
        this.datalist[index].children = res.body;
      } else {
        this.datalist[index].children = [];
      }
      this.randomKey = true;
      this.datalist.forEach((item) => {
        if (item.children === undefined) {
          this.randomKey = false;
        }
      });
    },

    checkedAllStudent() {
      console.log('this.checkedStudent---'+this.checkedStudent)
      if (this.checkedStudent) {
        //全选
        this.$refs.treeStudent.setCheckedNodes(this.studentlist);
      } else {
        //取消选中
        this.$refs.treeStudent.setCheckedKeys([]);
      }
    },
    checkedAll() {
      if (this.checked) {
        //全选
        this.$refs.tree.setCheckedNodes(this.datalist);
      } else {
        //取消选中
        this.$refs.tree.setCheckedKeys([]);
      }
    },

    async getTaskByUserAndCourse() {
      const res = await getTaskByCourse({
        courseId: this.$route.query.courseId
          ? this.$route.query.courseId
          : this.courseId,
        learnPurpose: "Y",
        status: "已启用",
      });
      this.datalist = res.body;
      this.datalist.forEach((item, index) => {
        this.getCourseWareList(item.id, index);
      });
    },

    handleCheckStudentChange() {
      this.studentCheck = this.$refs.treeStudent.getCheckedNodes(true);
    },

    handleCheckChange() {
      this.taskCheck = this.$refs.tree.getCheckedNodes(false, true);
    },

    batSetStatus(flag) {
      if (this.studentCheck.length === 0) {
        window.$msg("请先选择学员", 2);
        return false;
      } else if (this.taskCheck.length === 0) {
        window.$msg("请先选择要开放的补课视频", 2);
        return false;
      } else if (this.chooseDate.length === 0) {
        window.$msg("请设置视频有效期", 2);
        return false;
      }
      //开放或者关闭补课视频
      let thembArray = [];
      this.studentCheck.forEach((item) => {
        this.taskCheck.forEach((value) => {
          if (value.type === "补课视频") {
            let object = {
              attachmentId: value.id,
              userId: item.user_id,
              status: flag,
              startTime: this.chooseDate[0],
              endTime: this.chooseDate[1],
            };
            thembArray.push(object);
          }
        });
      });
      if (!thembArray.length) {
        return $msg("当前课节无补课视频！", 2);
      }
      insertAllWithStatus(thembArray).then((res) => {
        if (res.state === "success") {
          window.$msg(
            flag === "已通过" ? "批量开放补课视频成功" : "批量关闭补课视频成功"
          );
          this.close();
        }
      });
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  font-weight: bold;
}

.dialog-footer {
  text-align: left;
  margin-left: 30px;
  margin-bottom: 30px;
}

/deep/ .message {
  font-size: 16px;
  margin-bottom: 30px;
}

/deep/ .courselist {
  color: #222222;
  font-size: 16px;
  margin-bottom: 20px;
}

/deep/ .textshow {
  font-size: 14px;
  color: #222222;
  padding-right: 20px;
}

.tasktype {
  color: #722ed1;
  padding: 1px 3px;
  border: solid 1px;
  font-size: 12px;
  border-radius: 5px;
  margin-right: 5px;
}

.taskshowtype {
  color: #c05939;
  padding: 1px 3px;
  border: solid 1px;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 5px;
}

.tasklearnPurpose {
  color: #52c41a;
  padding: 1px 3px;
  border: solid 1px;
  font-size: 12px;
  border-radius: 5px;
  margin-right: 5px;
}

.taskpracticePurpose {
  color: #00a8c6;
  padding: 1px 3px;
  border: solid 1px;
  font-size: 12px;
  border-radius: 5px;
  margin-right: 5px;
}

/deep/ .el-tree-node__content {
  line-height: 35px;
  height: 35px;
}
</style>
