<template>
  <div>
    <el-row class="mb-20">
      <el-button @click="divideClass" size="small" type="primary" v-if="classInfo.status == '已启用' || classInfo.status == '待审核'">分 班</el-button>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="手机号" prop="loginName"></el-table-column>
      <el-table-column align="center" label="姓名" prop="userName"></el-table-column>
      <el-table-column align="center" label="性别" prop="sex"></el-table-column>
      <el-table-column align="center" label="入班日期">
        <template slot-scope="scope">
          <span>{{scope.row.createTime | dateFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="scope">
          <!-- <el-button @click="changePassword(scope.row)" plain size="small" type="primary" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">修改密码</el-button> -->
          <el-button
            v-if="pageFrom !== 'teacher'"
            @click="adjustClass(scope.row)"
            plain
            size="small"
            type="primary"
          >调 班</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 调班 -->
    <adjust-class :show="adjustDialog" @close="adjustDialog=false" :studentInfo="studentInfo" :user_id="user_id" @updateView="getClassStudentInfoById"></adjust-class>
    <!-- 修改密码dialog -->
    <change-password :resetUse="resetUse" :show="dialogPassword" @close="dialogPassword=false"></change-password>
    <!-- 分班 -->
    <divide-class :show="divideDialog" @close="divideDialog=false" :seleStuData="seleStuData" @editStuData="editStuData" :classInfo="classInfo"></divide-class>
  </div>
</template>

<script>
import { getClassStudentInfoById, addStudent, delStudent } from '@/api/merchantClass'
import changePassword from './change-password'
import divideClass from '@/components/merchantClass/divide-class'
import adjustClass from '@/components/merchantClass/adjust-class'

export default {
  props: {
    classStudent: {
      default: () => {
        return []
      },
      type: Array
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },pageFrom:{
      default: '',
      type: String
    },
  },
  data () {
    return {
      studentInfo:null,
      dataList: [],
      seleStuData: [],
      dialogPassword: false,
      resetUse: {},
      studentCourseObj: null,
      dialogStuCourse: false,
      class_id: '',
      course_id: '',
      user_id: '',
      divideDialog: false,
      adjustDialog:false
    }
  },
  components: { changePassword, divideClass,adjustClass },
  created () {
    if (!this.classStudent.length) {
      this.getClassStudentInfoById()
    } else {
      this.dataList = this.classStudent
    }
    this.class_id = this.$route.query.classID
    this.course_id = this.$route.query.courseId
  },
  methods: {
    adjustClass (row) {
      this.studentInfo = row
      this.user_id = row.user_id
      this.adjustDialog = true
    },
    async getClassStudentInfoById () {
      const res = await getClassStudentInfoById({
        class_id: this.$route.query.classID
      })
      this.dataList = res.body
    },

    // 修改密码
    changePassword (row) {
      this.resetUse = row
      this.dialogPassword = true
    },
    // 删除学员
    delStudent (row) {
      this.$confirm('是否删除该学员？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delStudent({
            classId: this.$route.query.classID,
            userIdSet: [row.user_id]
          })
          window.$msg('删除成功')
          this.getClassStudentInfoById()
        })
        .catch(() => {
          return false
        })
    },

    // 编辑学员-save
    async editStuData (data) {
      let userIdSet = []
      data.map((item, index) => {
        userIdSet.push(item.userId)
      })
      await addStudent({
        classId: this.$route.query.classID,
        userIdSet: userIdSet
      })
      window.$msg('更新班级成员成功')
      this.getClassStudentInfoById()
    },

    // 分班
    divideClass () {
      let stuData = []
      if (this.dataList.length !== 0) {
        this.dataList.map((item, index) => {
          stuData.push(
            Object.assign({}, item, {
              userId: item.user_id,
              name: item.userName
            })
          )
        })
        this.seleStuData = stuData
      }
      this.divideDialog = true
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
