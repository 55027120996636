<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    append-to-body
    top="20vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>增加排课时间</span>
    </div>

    <el-row style="margin-top: 16px;">
      <el-date-picker
        v-model="lessonTimeLocal"
        type="datetime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="请选择排课日期时间">
      </el-date-picker>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" >确 定</el-button>
      <el-button type="info"  @click="close">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessonTime: {
      default: '',
      type:String
    }
  },
  data() {
    return {
      lessonTimeLocal:'',
    }
  },
  methods: {
    openDialog () {
      this.lessonTimeLocal = this.lessonTime
    },

    close () {
      this.$emit('close')
    },

    confirm () {
      this.$emit('newLessonTime',this.lessonTimeLocal)
      this.close()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.el-dialog__body {
  padding-top: 16px !important;
}
</style>
