<template>
  <div>
    <el-row class="mb-20">
      <el-button @click="editClass" size="small" type="primary" v-if="pageFrom !== 'teacher'">编 辑</el-button>
      <el-button @click="userTeacherDialog = true" size="small" type="primary"   v-if="classInfo.course && classInfo.course.type !== '双师'">更新任课老师</el-button>
    </el-row>
    <el-card style="margin-bottom:10px;">
      <div>
        班级名称：
        <span v-if="classInfo" v-text="classInfo.className"></span>
      </div>
      <div style="margin:10px 0;" v-if="classInfo.course && classInfo.course.type !== '双师'">
        任课老师：
        <span v-if="classInfo" v-text="classInfo.zjName"></span>
      </div>
      <div style="margin:10px 0;">
        班级类型：
        <span >
          <span>{{this.classInfo.type}}</span>
        </span>
      </div>
      <div style="margin:10px 0;">
        授课课程：
        <span v-if="classInfo && this.classInfo.course != null">
          <span>{{this.classInfo.course.title}}</span>
        </span>
      </div>
    </el-card>

    <!-- 修改班级信息dialog -->
    <add-class
      :class_id="class_id"
      :product_id="product_id"
      :ordinaryClass="ordinaryClass"
      :classInfo="classInfo"
      :show="dialogClass"
      @close="dialogClass=false"
      @editClassForm="editClassForm"
      @getTeacherMessage="getTeacherMessage"
    ></add-class>

    <!-- 选择老师 dialog -->
    <sele-teacher
      :show="userTeacherDialog"
      @close="userTeacherDialog=false"
      entryType = "teacher"
      @seleTeacherData="seleTeacherData"
      :lessTeacher="lessTeacher"
    ></sele-teacher>
  </div>
</template>

<script>
import AddClass from '@/components/merchantClass/add-class'
import seleTeacher from '@/components/merchantClass/sele-teacher'
import { editClassData,replaceTeacher } from '@/api/merchantClass'

export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },pageFrom:{
      default: '',
      type: String
    },
  },

  components: { AddClass,seleTeacher },
  data () {
    return {
      dialogClass: false,
      ordinaryClass: false,
      updateCourse:false,
      lessTeacher:[],
      lessAssistant:[],
      userTeacherDialog: false,
      class_id: this.$route.query.classID, // 班级id
      product_id: this.$route.query.productId // 收费课程id
    }
  },
  methods: {
    // 编辑班级
    editClass () {
      this.ordinaryClass = true
      this.dialogClass = true
    },
    // 老师
    seleTeacherData (data) {
      this.lessTeacher = [ ...data ]
      if (this.$route.query.classID) {
        let userIdSet = []
        data.map(item => {
          userIdSet.push(item.userId)
        })
        replaceTeacher({
          classId: this.$route.query.classID,
          type: '主教',
          userIdSet: userIdSet
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('更新老师成功')
            this.getTeacherMessage()
          }
        })
      }
    },
    updateDate() {
      this.$emit('updateView')
    },
    // 编辑班级信息
    async editClassForm (data) {
      await editClassData({
        id: this.class_id,
        name: data.name,
        open_date:
          typeof data.open_date === 'object'
            ? data.open_date
            : data.open_date,
        campus_id: data.campus_id,
        lctype:data.lctype,
        type:data.type,
        classroom_id: data.classroom_id ? data.classroom_id[0] : '',
        course_id:
          data.status === '已启用'
            ? null
            : data.catalogId
            ? data.catalogId[data.catalogId.length - 1]
            : null,
      })
      window.$msg('编辑班级成功')
      this.$emit('updateView')
    },

// dateKey 时间处理
    datefmt (date) {
      let res = ''
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      res = `${year}-${month}-${day}`
      return res
    },

    getTeacherMessage () {
      this.$emit('updateView')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
