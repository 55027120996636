<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <span>排课数据</span>
        <div class="flex_1"></div>
        <el-button style="float: right" type="primary" size="small"
                   v-if="pageFrom !== 'teacher' && classInfo && classInfo.course && classInfo.course.type !== '双师'"
                   plain @click="batchAdd">新增排课
        </el-button>
        <el-button style="float: right" type="primary" size="small"
                   v-if="pageFrom !== 'teacher' && classInfo && classInfo.course &&classInfo.course.type !== '双师'" plain
                   @click="modifyLessontime">修改排课时间
        </el-button>
        <el-button style="float: right" type="primary" size="small"
                   v-if="pageFrom !== 'teacher' && classInfo && classInfo.course &&classInfo.course.type !== '双师'" plain
                   @click.native="batchMissCourseFlag = true">批量开放补课视频
        </el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          :highlight-current-row="true"
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'start_time', order: 'ascending' }"
        >
          <!-- style="margin-top: 15px;" -->
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '上课时间'">
                <span>
                  <span>（{{ scope.row.start_time.substring(0, 10) | weekDay }}）</span>
                  {{ scope.row.start_time | formatDateStart }} {{ scope.row.end_time | formatDateEnd }}</span>
              </template>
              <template v-else-if="item.label === '课次'">
                <el-row>
                  <span>{{ scope.row.courseUnitlist[0].sort }}</span>
                </el-row>
              </template>
              <template v-else-if="item.label === '上课学员'">
                <el-popover
                  v-if="scope.row.lessonStudentList !== null"
                  placement="right"
                  width="350"
                  style="height: 400px;overflow-y: scroll"
                  trigger="click">
                  <el-table :data="scope.row.lessonStudentList">
                    <el-table-column property="name" label="姓名" align="center"></el-table-column>
                    <el-table-column width="160" property="loginName" label="登录名" align="center"></el-table-column>
                    <el-table-column label="出勤状态" align="center">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isAttend === '是'" type="success">已出勤</el-tag>
                        <el-tag v-else type="danger">未出勤</el-tag>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button slot="reference" type="text" style="text-decoration:underline">
                    {{ scope.row.lessonStudentList.length }}
                  </el-button>
                </el-popover>
                <span v-else>0</span>
              </template>
              <template v-else-if="item.label === '课节标题'">
                <el-row v-if="scope.row.courseUnitlist.length != 0" :key="index"
                        v-for="(item,index) in scope.row.courseUnitlist">
                  <span>{{ item.title }}</span>
                </el-row>
                <el-row v-if="scope.row.courseUnitlist.length == 0">
                  <span>无</span>
                </el-row>
              </template>
              <!--              <template v-else-if="item.label === '排课操作'">-->
              <!--                <er-row v-if="classInfo.course.type === '双师'">无</er-row>-->
              <!--                <el-button type="text" v-else-if="classInfo.course.type !== '双师'"  @click.native="editLess(scope.row)">编辑排课</el-button>-->
              <!--              </template>-->
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        class="fr-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

    </el-card>

    <add-lesson
      :addlessonData="addlessonData"
      :show="dialogLesson"
      :campusId="classInfo.campus_id"
      :classInfo="classInfo"
      @close="close"
      @editLessonForm="editLessonForm"
      @getClassMessage="getLessonInfoByClassId"
    ></add-lesson>
    <!-- 批量增加排课 -->
    <batch-add :show="batchDialog" @close="batchDialog=false" @getClassMessage="getLessonInfoByClassId"
               :classInfo="classInfo"></batch-add>
    <!-- 批量修改排课时间 -->
    <modify-lessontime :show="modifyDialog" @close="modifyDialog=false" :isDesc="isDesc"></modify-lessontime>
    <batch-open-miss-course
      :show="batchMissCourseFlag"
      @close="batchMissCourseFlag = false"
    ></batch-open-miss-course>
  </div>
</template>

<script>
import TextButton from '@/components/TextButton'
import SearchQuery from '@/components/SearchQuery'
import batchAdd from '../batch-add/index'
import modifyLessontime from '../modifylessontime/index'
import getStateColor from '@/mixins/getStateColor'
import batchOpenMissCourse from '../batchOpenMissCourse/index'
import addLesson from '../add-lesson/index'

import { listCourseUnit, getClassStudentInfoById, getLessonInfoByClassId, lessonEdit } from '@/api/merchantClass'

// 默认展示列
const baseColSetting = [
  {
    label: '课次',
    state: true,
    align: 'left',
    width: '50',
  },
  {
    label: '上课时间',
    prop: 'start_time',
    state: true,
    sortable: true,
    align: 'center',
    width: '255',
  },
  {
    label: '上课学员',
    prop: '',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '教师名称',
    prop: 'zjName',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '课节标题',
    prop: 'courseUnitlist',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '排课状态',
    prop: 'finished_status',
    state: true,
    align: 'center',
    width: '100',
  },
]
export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }, pageFrom: {
      default: '',
      type: String
    },
  },
  mixins: [getStateColor],
  components: { TextButton, SearchQuery, batchAdd, addLesson, modifyLessontime, batchOpenMissCourse },
  data () {
    return {
      dialogLesson: false,
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      },
      dataList: [],
      tableKey: 1,
      isDesc: false, // 列表默认为正序
      product_id: this.$route.query.productId, // 收费课程id
      lessonDialog: false,
      courseUnitIdList: [],
      lesson_id: '',
      classId: '',
      addlessonData: null,
      batchDialog: false,
      modifyDialog: false,
      batchMissCourseFlag: false,
      DeedBackDialog: false,
      attendanceDialog: false,
      schooltime: '', // 上课时间
      stuList: [], // 学员列表
      dialogJionLive: false,
      liveRoomId: '',
      dialogCode: false,
      dialogLive: false,
      lessonLiveName: '',
      liveData: null,
      lessonLiveData: null,
    }
  },
  created () {
    this.getLessonInfoByClassId()
    this.getClassStudentInfoById()
  },
  computed: {
    defaultHeader () {
      return this.colSetting.filter(item => item.state)
    },
  },
  methods: {
    // 编辑排课
    async editLessonForm (data) {
      let editLesson = {
        id: data.id,
        start_time: data.start_time,
        end_time: data.endTime,
      }
      const res = await lessonEdit({ ...editLesson })
      if (res.state === 'success') {
        window.$msg('编辑成功')
        this.getLessonInfoByClassId()
        this.close()
      } else {
        if (res.errCode === '0201001') {
          this.clashFun(res)
        }
      }
      // this.mapCourseUnits(data.id,data.courseUnitId)
      // this.getCourseUnitList();
    },
    async getLessonInfoByClassId () { // 获取班级排课列表
      const res = await getLessonInfoByClassId({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        class_id: this.$route.query.classID,
        isDesc: this.isDesc
      })
      const dataList = res.body.list
      this.dataList = dataList
      this.pagination.total = res.body.total
    },

    jumpLive (row) {
      this.liveRoomId = row.liveId
      this.dialogJionLive = true
    },

    async getClassStudentInfoById () {
      const res = await getClassStudentInfoById({
        class_id: this.$route.query.classID
      })
      if (res.state === 'success') {
        this.stuList = res.body
      }
    },

    update (data) {
      this.tableKey = this.tableKey + 1
      if (data.lenght !== 0) {
        this.colSetting.forEach(item => {
          item.state = false
          data.forEach(ele => {
            if (ele === item.label && !item.state) {
              item.state = true
            }
          })
        })
      } else {
        this.colSetting = []
      }
    },

    // 批量排课
    batchAdd () {
      this.batchDialog = true
    },
    modifyLessontime () {
      this.modifyDialog = true
    },
    search () {
      this.pagination.currentPage = 1
      this.getLessonInfoByClassId()
    },

    // 时间排序
    tableSortAll (column) {
      switch (column.order) {
        case 'ascending':
          this.isDesc = false
          break
        case 'descending':
          this.isDesc = true
      }
      this.getLessonInfoByClassId()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getLessonInfoByClassId()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getLessonInfoByClassId()
    },

    getStateColor (state) {
      switch (state) {
        case '已上课':
          return '#4DA54D'
        case '未上课':
          return '#f56c6c'
        default:
          return '#C8C6C6'
      }
    },

    // 编辑排课
    editLess (row) {
      if (row.finished_status === '已上课') {
        window.$msg('已上课的排课不能编辑排课', 2)
        return false
      } else if (row.finished_status === '正在上课') {
        window.$msg('正在上课的排课不能编辑排课', 2)
        return false
      }
      let studentIds = []
      if (this.stuList.length !== 0) {
        this.stuList.map(item => {
          studentIds.push(item.user_id)
        })
      }
      this.class_id = row.class_id
      this.listCourseUnit(row)
    },

    async listCourseUnit (row) {
      const res = await listCourseUnit({
        lessonId: row.id
      })
      if (res.state === 'success') {
        var courseUnitId = []
        for (let i = 0; i < res.body.length; i++) {
          courseUnitId.push(res.body[i].id)
        }
        this.addlessonData = row
        this.addlessonData.courseUnitId = courseUnitId

        this.dialogLesson = true
      }
    },
    close () {
      this.dialogLesson = false
    },
  },

}
</script>

<style lang="scss" scoped>
@import '../../../style/container.scss';
</style>
