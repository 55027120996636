var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "mb-20" },
        [
          _vm.pageFrom !== "teacher"
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.editClass },
                },
                [_vm._v("编 辑")]
              )
            : _vm._e(),
          _vm.classInfo.course && _vm.classInfo.course.type !== "双师"
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userTeacherDialog = true
                    },
                  },
                },
                [_vm._v("更新任课老师")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-card", { staticStyle: { "margin-bottom": "10px" } }, [
        _c("div", [
          _vm._v(" 班级名称： "),
          _vm.classInfo
            ? _c("span", {
                domProps: { textContent: _vm._s(_vm.classInfo.className) },
              })
            : _vm._e(),
        ]),
        _vm.classInfo.course && _vm.classInfo.course.type !== "双师"
          ? _c("div", { staticStyle: { margin: "10px 0" } }, [
              _vm._v(" 任课老师： "),
              _vm.classInfo
                ? _c("span", {
                    domProps: { textContent: _vm._s(_vm.classInfo.zjName) },
                  })
                : _vm._e(),
            ])
          : _vm._e(),
        _c("div", { staticStyle: { margin: "10px 0" } }, [
          _vm._v(" 班级类型： "),
          _c("span", [_c("span", [_vm._v(_vm._s(this.classInfo.type))])]),
        ]),
        _c("div", { staticStyle: { margin: "10px 0" } }, [
          _vm._v(" 授课课程： "),
          _vm.classInfo && this.classInfo.course != null
            ? _c("span", [
                _c("span", [_vm._v(_vm._s(this.classInfo.course.title))]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("add-class", {
        attrs: {
          class_id: _vm.class_id,
          product_id: _vm.product_id,
          ordinaryClass: _vm.ordinaryClass,
          classInfo: _vm.classInfo,
          show: _vm.dialogClass,
        },
        on: {
          close: function ($event) {
            _vm.dialogClass = false
          },
          editClassForm: _vm.editClassForm,
          getTeacherMessage: _vm.getTeacherMessage,
        },
      }),
      _c("sele-teacher", {
        attrs: {
          show: _vm.userTeacherDialog,
          entryType: "teacher",
          lessTeacher: _vm.lessTeacher,
        },
        on: {
          close: function ($event) {
            _vm.userTeacherDialog = false
          },
          seleTeacherData: _vm.seleTeacherData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }