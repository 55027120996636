var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "70%",
        top: "7vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("学员分班")])]
      ),
      _c(
        "el-row",
        { attrs: { gutter: 60 } },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c(
                "el-row",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "waitList",
                      attrs: {
                        data: _vm.waitList,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                        "row-key": _vm.getRowKeys,
                      },
                      on: { "selection-change": _vm.waithandleselectChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                          "reserve-selection": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "用户名",
                          align: "center",
                          prop: "loginName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "姓名", align: "center", prop: "name" },
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticClass: "pagination",
                    attrs: {
                      "current-page": _vm.waitPagination.currentPage,
                      "page-sizes": _vm.waitPagination.pageSizes,
                      "page-size": _vm.waitPagination.pageSize,
                      layout: _vm.waitPagination.layout,
                      total: _vm.waitPagination.total,
                    },
                    on: { "current-change": _vm.waithandleCurrentChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-row",
                { staticClass: "haschoose" },
                [
                  _c("el-row", { staticClass: "seleRow" }, [
                    _c("span", [_vm._v("已选择：")]),
                    _c("span", {
                      staticClass: "seleNum",
                      domProps: {
                        textContent: _vm._s(_vm.multipleSelectionTotal.length),
                      },
                    }),
                  ]),
                  _c(
                    "ul",
                    { staticStyle: { height: "500px", overflow: "auto" } },
                    _vm._l(_vm.multipleSelectionTotal, function (item, index) {
                      return _c("li", { key: index, staticClass: "seleList" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.name) +
                            " [" +
                            _vm._s(item.loginName) +
                            "] "
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm.isNonentity
                ? _c(
                    "el-row",
                    {
                      staticStyle: {
                        "box-shadow": "0px 2px 9px 0px rgba(0, 0, 0, 0.15)",
                        "padding-bottom": "30px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c("span", { staticClass: "seleRow" }, [
                        _vm._v("不存在的用户名"),
                      ]),
                      _vm._l(_vm.nonexistence, function (item, index) {
                        return _c(
                          "el-row",
                          { key: index, staticClass: "seleList" },
                          [_c("span", [_vm._v(_vm._s(item))])]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn1",
              attrs: { type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn1",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }