<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>学员分班</span>
    </div>
    <el-row :gutter="60">
      <el-col :span="14">
        <el-row style="margin-bottom:20px">
          <el-table
            :data="waitList"
            ref="waitList"
            border
            fit
            highlight-current-row
            :row-key="getRowKeys"
            @selection-change="waithandleselectChange"
          >
            <el-table-column type="selection" width="50" align="center" :reserve-selection="true"></el-table-column>
            <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
            <el-table-column label="姓名" align="center" prop="name"></el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            @current-change="waithandleCurrentChange"
            :current-page="waitPagination.currentPage"
            :page-sizes="waitPagination.pageSizes"
            :page-size="waitPagination.pageSize"
            :layout="waitPagination.layout"
            :total="waitPagination.total"
          ></el-pagination>
        </el-row>
      </el-col>
      <el-col :span="10">
        <el-row class="haschoose" >
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum" v-text="multipleSelectionTotal.length"></span>
          </el-row>
            <ul style="height: 500px;overflow: auto">
              <li v-for="(item, index) of multipleSelectionTotal" :key="index" class="seleList">
                {{item.name}} [{{item.loginName}}]
              </li>
            </ul>

        </el-row>

        <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;margin-top: 15px;" v-if="isNonentity">
          <span class="seleRow">不存在的用户名</span>
          <el-row v-for="(item, index) of nonexistence" :key="index" class="seleList">
            <span>{{item}}</span>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn1">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn1">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { listStudentForClass } from '@/api/merchantClass'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    seleStuData: {
      default: () => {
        return []
      },
      type: Array
    },
    isShowAll: {
      default: false,
      type: Boolean
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      tagSet:[],
      props: {
        multiple: true,
        value: 'id',
        label: 'title'
      },
      waitList: [], // 待分班学员列表
      waitPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: 'total, prev, pager, next, jumper',
        total: 0
      },
      allPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: 'total, prev, pager, next, jumper',
        total: 0
      },
      multipleSelectionTotal: [], // 已选择学员
      multipleSelectionWait: [], // 待分班学员
      multipleSelectionAll: [], // 全部学员
      idKey: 'userId',
      inputVal: '',
      select: '',
      isNonentity: false,
      batchStr: '',
      nonexistence: [],
    }
  },
  methods: {
    openDialog () {
      this.batchStr = ''
      this.waitPagination.currentPage = 1
      this.allPagination.currentPage = 1
      this.isNonentity = false
      this.multipleSelectionWait = []
      this.multipleSelectionAll = []
      this.nonexistence = []
      this.multipleSelectionTotal = this.seleStuData
      this.getListStudentForClass()
    },

    getRowKeys (row) {
      return row.userId
    },

    // 获取待分班学员列表
    async getListStudentForClass () {
      const res = await listStudentForClass({
        pageNum: this.waitPagination.currentPage,
        pageSize: this.waitPagination.pageSize,
        classId: this.$route.query.classID,
      })
      res.body.list.forEach((item, idx) => {
        item.userId = item.id
      })
      this.waitList = res.body.list
      this.waitPagination.total = res.body.total
      this.mergeCheck()
    },

    search () {
      this.allPagination.currentPage = 1
      this.chooseLoadList()
    },


    // 记忆勾选
    mergeCheck () {
      this.waitList.forEach((row, index) => {
        this.multipleSelectionTotal.forEach((ele, idx) => {
          if (row.userId === ele.userId) {
            this.$refs.waitList.toggleRowSelection(this.waitList[index], true)
          }
        })
      })
    },


    clearAllMerge (newVal, oldVal) {
      let ids = []
      oldVal.forEach((ele) => {
        ids.push(ele.userId)
      })
      let idKey = this.idKey
      for (let i = 0; i < this.waitList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.waitList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.waitList.toggleRowSelection(this.waitList[i], false)
          }
        }
      }
    },

    waithandleselectChange (val) {
      this.multipleSelectionWait = val
      this.multipleSelectionTotal = this.getNewList(this.multipleSelectionAll.concat(val).concat(this.seleStuData))
    },

    allhandleselectChange (val) {
      this.multipleSelectionAll = val
      this.multipleSelectionTotal = this.getNewList(this.multipleSelectionWait.concat(val).concat(this.seleStuData))
    },

    // 关闭
    close () {
      this.$emit('close')
      this.$refs.waitList.clearSelection()
    },

    // 分页
    waithandleCurrentChange (val) {
      this.waitPagination.currentPage = val
      this.getListStudentForClass()
    },

    // 保存
    confirm () {
      let thembArray = []
      for (let i = 0; i < this.multipleSelectionTotal.length; i++) {
        let flag = false
        for (let j = 0; j < this.seleStuData.length; j++) {
          if(this.multipleSelectionTotal[i].userId === this.seleStuData[j].userId) {
            flag = true
            break
          }
        }

        if(!flag) {
          thembArray.push(this.multipleSelectionTotal[i])
        }
      }

      this.$nextTick(() => {
        this.$emit('editStuData', thembArray)
        this.close()
      })
    },

    getNewList (arr, userId) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.userId) && res.set(a.userId, 1))
    },

    // 删除学员
    delStu (index, id) {
      if (!this.multipleSelectionTotal || this.multipleSelectionTotal.length <= 0) {
        return
      }
      this.multipleSelectionTotal.splice(index, 1)
      // 标识当前行的唯一键的名称
      let idKey = this.idKey
      let selectAllIds = id
      for (let i = 0; i < this.waitList.length; i++) {
        if (selectAllIds.indexOf(this.waitList[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.waitList.toggleRowSelection(this.waitList[i], false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
/deep/ .el-dialog {
  overflow: auto;
}
.haschoose {
  box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 400px;
    margin-right: 38px;
  }
}
</style>
